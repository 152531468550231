import React, { useContext, useState } from 'react';
import { Box, TextField, FormControl, FormLabel, IconButton, InputAdornment } from '@mui/material'; // Import FormControl and FormLabel
import './signin.css';
import * as yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { signInWithPopup } from 'firebase/auth';
import { toast } from 'react-toastify';
import api from '../../api/index';
import googleLogo from '../../assets/images/googlelogo.png';
import { AiFillInfoCircle, AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import logo from '../../assets/images/logo.jpg';
import { auth, provider } from '../../config/firebaseConfig';
import { AuthContext } from '../../context/auth';
import { useFormik } from 'formik';
import { PlansContext } from '../../context/plans/plans';
import AuthLayout from '../../components/authLayout/authLayout';

const Signin = () => {
  const { isLogin, loginSuccess } = useContext(AuthContext);
  const { handleValidatePlan } = useContext(PlansContext);

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const initialValues = {
    email: '',
    password: '',
  };

  const [showpassword, setShowpassword] = useState(false);

  const validationSchema = yup.object().shape({
    email: yup.string().email('Invalid email format').required('Please Enter Your Email'),
    password: yup.string().required('Please Enter Your Password'),
  });
  
  const onSubmit = async (values, resetForm) => {
    try {
      setIsLoading(false);
      const res = await api.post('/user/login', values);
      loginSuccess(res.data.token, res.data.data.user);
      navigate('/');
      resetForm();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast(error?.response?.data?.message, { type: 'error' });
    }
  };

  const handleOnClick = async () => {
    signInWithPopup(auth, provider).then(async (res) => {
      let data = {
        name: res?.user?.displayName,
        email: res?.user?.email,
        photo: res?.user?.photoURL,
        token: res?.user?.accessToken,
      };
      try {
        let res = await api.post('/user/googleSignin', data);
        loginSuccess(res.data.token, res.data.data.user);
        navigate('/');
      } catch (error) {
        toast('Error while logged in: ', { type: 'error' });
      }
    });
  };

  const formik = useFormik({ initialValues, validationSchema, onSubmit });

  return (
    <AuthLayout>
    <div className="signin_main">
      {/* <div className="pic_div-login">
        <a href="https://www.datatera.ai/" target="_blank">
          <img src={logo} className="logo" />
        </a>
      </div> */}
      <div className="signinUpper">
        <div className="signin">
          <h6 className="login_heading">Login</h6>
          <p className="explore_future_heading">Explore the future with us</p>
          <Box className="form" component="form" noValidate onSubmit={formik.handleSubmit}>
            <FormControl fullWidth margin="normal">
              <FormLabel htmlFor="email" className={formik.errors.email && formik.touched.email ? 'error' : ''}>
                Email <span style={{ color: 'red' }} className='mt-1'>*</span>
              </FormLabel>
              <TextField
                type="email"
                name="email"
                variant="outlined"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                required
              />
            </FormControl>
            <div className="eye_icon_main">
              <FormControl fullWidth margin="normal">
                <FormLabel htmlFor="password"  className={formik.errors.password && formik.touched.password ? 'error' : ''}>
                  Password <span style={{ color: 'red' }}>*</span>
                </FormLabel>
                <TextField
                  type={showpassword ? 'text' : 'password'}
                  name="password"
                  variant="outlined"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.password && Boolean(formik.errors.password)}
                  helperText={formik.touched.password && formik.errors.password}
                  required
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                        >
                          {!showpassword ? <AiOutlineEye
                  className="eye_icon"
                  onClick={(e) => {
                    e.preventDefault();
                    setShowpassword(!showpassword);
                  }}
                /> :   <AiOutlineEyeInvisible
                  className="eye_icon"
                  onClick={() => setShowpassword(!showpassword)}
                />
                          }
                        </IconButton>
                        {formik.errors.password && formik.touched.password ? (
                          <AiFillInfoCircle  sx={{ color: '#DC3545' }} fontSize="medium" />
                        ) : (
                          ''
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            
            </div>
            <div className="forgot_password_div">
              <Link to="/forgot-password" className="forgot_password_link">
                Forgot Password?
              </Link>
            </div>
            <div className="signin_button_main">
              <Button type="submit" className="signin_login_btn" disabled={isLoading}>
                Login
              </Button>
            </div>
          </Box>
          <div className="signin_div_main">
            <p className="signin_with_email">
              <span className="signin_email_span">Or sign in with e-mail</span>
            </p>
            <div className='register-sign-in'>
              <Link to="/register">
                <Button className="register_btn" type="submit">
                  Register
                </Button>
              </Link>
              {/* <div className="signin_div" > */}
              <Button className="register_btn_sign_in_google" onClick={() => handleOnClick()}>
              <img src={googleLogo} className="googlelogo" /> Sign in with Google
                </Button>
               
              {/* </div> */}
            </div>
          </div>
          <div className="d-flex justify-content-center mt-5">
            <div>
              Copyright &copy;{' '}
              <a href="https://www.datatera.ai/" target="_blank">
                Datatera.ai
              </a>
            </div>
            <div className="ms-2">
              <a href="https://www.datatera.ai/terms-of-service/" target="_blank">
                Terms of Service
              </a>
            </div>
            <div className="ms-2">
              <a href="https://www.datatera.ai/privacy-policy/" target="_blank">
                Privacy Policy
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    </AuthLayout>
    
  );
};

export default Signin;
