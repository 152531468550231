import React from "react";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  let token = localStorage.getItem("token");
  
  // If token exists but is not in JSON format, handle accordingly
  if (token) {
    try {
      token = JSON.parse(token); // parse token only if it's a JSON string
    } catch (e) {
      console.error("Error parsing token:", e);
      token = null; // If parsing fails, consider token as invalid
    }
  }
  
  // If no token, allow access to the route
  if (!token) {
    return children;
  }
  
  // If token exists, redirect to home page
  return <Navigate to="/" />;
};

const ProtectedAuthRoute = ({ children }) => {
  let token = localStorage.getItem("token");
  
  // If token exists but is not in JSON format, handle accordingly
  if (token) {
    try {
      token = JSON.parse(token); // parse token only if it's a JSON string
    } catch (e) {
      console.error("Error parsing token:", e);
      token = null; // If parsing fails, consider token as invalid
    }
  }
  
  // If no token, redirect to signin page
  if (!token) {
    return <Navigate to="/signin" />;
  }
  
  // If token exists, allow access to the route
  return children;
};

const OpenRoute = ({ children }) => {
  return children;
};

export { ProtectedAuthRoute, ProtectedRoute, OpenRoute };
