import { useState, useRef ,useEffect } from "react";
import './UploadProfile.css';
import { AiOutlineEdit, AiOutlineUpload } from "react-icons/ai";
import api from "../../../api";

const UploadProfile = ({ imageUrl }) => {
  const [selectedImage, setSelectedImage] = useState(null); // Initially no image
  const fileInputRef = useRef(); // Ref for the hidden file input

  // Handle image selection
  const handleChange = (e) => {
    if (e.target.files) {
      setSelectedImage(e.target.files[0]); // Set the selected file
    }
  };

  // Upload the selected image immediately when selected
  const uploadImage = async (image) => {
    const formData = new FormData();
    formData.append('file', image);

    try {
      const res = await api.patch('/upload-profile', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log('Image uploaded successfully:', res.data);
    } catch (error) {
      console.error('Error uploading image:', error);
    }
  };

  // Handle profile pic click to open file input
  const handleProfileClick = () => {
    fileInputRef.current?.click(); // Trigger file input click
  };

  // Effect to trigger image upload as soon as the image is selected
  useEffect(() => {
    if (selectedImage) {
      uploadImage(selectedImage); // Upload the image as soon as it's selected
    }
  }, [selectedImage]);

  return (
    <div className="container">
      <div className={'image-wrapper image-unselected'}>
        <div className="flex items-center justify-center w-full">
          <div className="relative">
            {/* If no profile image exists, show upload icon */}
            {!selectedImage && !imageUrl ? (
              <div className="upload-icon" onClick={handleProfileClick}>
                <p className="text">Profile Pic</p>
                <AiOutlineUpload />
              </div>
            ) : (
              <>
                {/* If an image is selected or exists, show the image */}
                <img
                  src={selectedImage ? URL.createObjectURL(selectedImage) : imageUrl}
                  style={{
                    width: "8rem",
                    height: "8rem",
                    borderRadius: "50%",
                    cursor: "pointer", // Make the image clickable
                  }}
                  alt="Profile"
                  onClick={handleProfileClick} // Trigger file input on image click
                />
                <button onClick={handleProfileClick} className="button">
                  <AiOutlineEdit className="text-white" />
                </button>
              </>
            )}
            {/* Render file input element */}
            <input
              ref={fileInputRef}  // Attach ref to the input
              id="dropzone-file"
              type="file"
              accept="image/*"
              className="hidden"
              onChange={handleChange}  // Handle file selection here
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadProfile;
