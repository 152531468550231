import ReactPaginate from "react-paginate";
import './Pagination.css';

const Pagination = ({ totalRecords = 50, currentPage = 1, onPageChange, firstPage, lastPage, setCurrentPage }) => {
  let itemsPerPage = 10;
  const pageCount = Math.ceil(totalRecords / itemsPerPage);

  return (
    <div className="pagination-container d-flex align-items-baseline">
      <button
        className="pagination-button" type="button"
        onClick={() => { setCurrentPage(firstPage) }} >
        First
      </button>

      <ReactPaginate
        breakLabel={
          <div className="pagination-break">
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
          </div>
        }
        nextLabel={
          <div className="d-flex">
            <button
              type="button"
              className="pagination-button"
            >
              Next
            </button>
          </div>
        }
        marginPagesDisplayed={1}
        pageRangeDisplayed={2}
        pageCount={pageCount}
        forcePage={currentPage}
        previousLabel={
          <div className="d-flex">
            <button
              type="button"
              className="pagination-button"
              style={{ marginLeft: "10px" }}
            >
              Previous
            </button>
          </div>
        }
        onPageChange={onPageChange}
        pageClassName="pagination-item"
        pageLinkClassName="pagination-link"
        activeLinkClassName="active"
      />
      
      <button
        type="button"
        className="pagination-button"
        style={{ marginLeft: "10px" }}
        onClick={() => { setCurrentPage(lastPage) }} >
        Last
      </button>
    </div>
  );
};

export default Pagination;
