import React, { useContext, useEffect, useState } from 'react';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import './UploadModal.css';
import { Checkbox, FormControlLabel, Input, styled, Tooltip, TextField } from '@material-ui/core';
import { toast } from 'react-toastify';
import Joyride from 'react-joyride';
import useWindowDimensions from '../../utiles/getWindowDimensions';
import { UpdateToken } from '../../helper/UpdateToken';
import { PlansContext } from '../../context/plans/plans';
import close from '../../assets/images/close-icon.png';
import api from '../../api';
import { ListContext } from '../../context/list';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  tooltip: {
    fontSize: '14px',
  },
  arrow: {
    color: '#000',
  },
}));

const UploadModal = (props) => {
  const classes = useStyles();
  const {
    handleClose,
    show,
    setUploadTextShow,
    handleChangeUploadFile,
    handleChangeUploadFileInBackground,
    handleUploadFile,
    loading,
    textCheckBox,
    setTextCheckBox,
    merge,
    setMerge,
    searchQuery,
    setSearchQuery,
    entireWebsite,
    setEntireWebsite,
    pagination,
    setPagination,
    noOfPages,
    setNoOfPages,
    defaultValues,
    conId,
    data,
    setData,
    filesSelected,
    setFilesSelected,
  } = props;

  const {
    list,
  } = useContext(ListContext);
  const { userPlan } = useContext(PlansContext);
  const CustomCheckbox = styled(Checkbox)`
    color: green; /* Replace with your desired shade of green */
    &:focus {
      background-color: green; /* Replace with your desired focus color */
    }
    &.Mui-checked {
      color: green; /* Replace with your desired shade of green */
    }
  `;
  const [savingCheck, setSavingCheck] = useState(false);

  const [returnRowsLimit, setReturnRowsLimit] = useState(0);
  const [model, setModel] = useState(1);
  const [uploadText, setUploadText] = useState('');

  // const joyrideOptions = {
  //   // Other options...
  //   styles: {
  //     options: {
  //       // Style the beacon
  //       beacon: {
  //         backgroundColor: 'blue',
  //         color: 'white',
  //         fontSize: '16px',
  //         // Other styles...
  //       },
  //       // Other styles...
  //     },
  //   },
  // };
  // const [steps, setSteps] = useState([
  //   {
  //     index: 0,
  //     type: 'step:before',
  //     target: '#upload_file_convert_background_label',
  //     placementBeacon: 'bottom',

  //     content: (
  //       <p>
  //         After clicking this button you will be prompted to select a file to be
  //         converted according to the sample you uploaded previously.If you want
  //         to convert data from websites by providing URLs within the file you’re
  //         uploading, please BEFORE upload check the checkbox “Process URLs” at
  //         the bottom of the form. The number of URLs you can process depends on
  //         your subscription.
  //       </p>
  //     ),
  //   },
  //   {
  //     index: 1,
  //     target: '#textareastep',
  //     placement: 'right-end',
  //     type: 'step:before',
  //     content: (
  //       <p>
  //         In the text field, you can directly copy text. For example from email,
  //         messenger, or a website.If you want to convert data from websites by
  //         providing URLs within the text box (for example
  //         “https://www.producthunt.com/ https://www.g2.com/ …”), please BEFORE
  //         hitting the button “TEXT” check the checkbox “Process URLs” at the
  //         bottom of the form.The number of URLs you can process depends on your
  //         subscription.
  //       </p>
  //     ),
  //   },

  //   {
  //     index: 2,
  //     target: '#step3btn_background',
  //     type: 'step:before',
  //     content: (
  //       <p>
  //         When the text in the text box is ready, you can submit it to the
  //         conversion by hitting this button. Text should be no less than 10
  //         characters to be submitted.
  //       </p>
  //     ),
  //   },
  //   {
  //     index: 3,
  //     target: '#stepfour',
  //     type: 'step:before',
  //     content: (
  //       <p>
  //         When enabled, the “Process URLs” checkbox allows extraction of all
  //         URLs from uploaded file or text and convert these URLs 1-by-1.
  //       </p>
  //     ),
  //   },
  //   {
  //     index: 4,
  //     target: '#stepfive',
  //     type: 'step:before',
  //     content: (
  //       <p>
  //         When enabled, the “Smart Merge” checkbox will tell conversion to merge
  //         the intelligent rows after conversion to one row. For example, if
  //         conversion returns more than one row of data: “Smart Merge” UNchecked
  //         result - 4 rows: - Row 1. Value: “US Tesla Stores and Galleries” Row
  //         2. “Tesla Stores in Puerto Rico” Row 3. “Tesla Stores in Mexico” Row
  //         4. “Tesla Stores in Canada” “Smart Merge” checked result - 1 row : -
  //         Row 1. “US Tesla Stores and Galleries, Tesla Stores in Puerto Rico,
  //         Tesla Stores in Mexico, Tesla Stores in Canada” If you are processing
  //         URLs, each URL will be merged separately. For example, for 2 website
  //         URLs, there will be always 2 rows of results when “Smart Merge” is
  //         checked.
  //       </p>
  //     ),
  //   },
  //   {
  //     index: 5,
  //     target: '#stepsix',
  //     type: 'step:before',
  //     content: (
  //       <p>
  //         You can define the maximum number of rows the conversion will return.
  //         For example, if you know that from the exact file or text or website,
  //         you need exactly or no more than 5 rows, by defining 5 in “Return rows
  //         limit” you will get no more than 5 rows as a result. This sometimes
  //         makes sense to get results quicker. Set “Return rows limit” to 0 for
  //         the unlimited number of rows in the result. The number of rows in the
  //         result can be limited within your subscription even if you’re setting
  //         0.
  //       </p>
  //     ),
  //   },
  //   {
  //     index: 6,
  //     target: '#stepseven',
  //     type: 'step:before',
  //     content: (
  //       <p>
  //         “AI model” is a technical parameter for our AI unit. Please leave it’s
  //         value equal to 1.
  //       </p>
  //     ),
  //   },
  // ]);

  // const onJoyrideCallback = (data) => {
  //   if (data.index === 6 && data.lifecycle === 'complete') {
  //   }
  // };

  useEffect(() => {
    if (show && defaultValues) {
      setTextCheckBox(Boolean(defaultValues?.processUrls));
      setMerge(Boolean(defaultValues?.merge));
      setSearchQuery(Boolean(defaultValues?.search));
      setModel(defaultValues?.model);
      
      if (Boolean(defaultValues?.processUrls)) {
        const Pageinate =
          userPlan?.[0]?.subType?.Pagination ||
          userPlan?.[0]?.subscriptionType?.Pagination;
        if (Pageinate === 1) {
          setPagination(Boolean(defaultValues?.Pagination));
        }
        
        const EntireSite =
          userPlan?.[0]?.subType?.entireWebsite ||
          userPlan?.[0]?.subscriptionType?.entireWebsite;
        if (EntireSite === 1) {
          setEntireWebsite(Boolean(defaultValues?.entireWebsite));
        }
      }
      
      // if (defaultValues?.pagination_max_iteractions) {
      //   setNoOfPages(defaultValues?.pagination_max_iteractions);
      // }
    }
  }, [show, defaultValues, userPlan]);

  const updateCheck = async (label, value) => {
    try {
      setSavingCheck(true);
      await api
        .put(`/conversion/updateConversionCheck/${conId || list}`, {
          label,
          value: label === 'pagination_max_iteractions' ? Number(value) : Number(value),
        })
        .then((res) => {
          if (res?.data) {
            // Update all checkbox states based on the response
            setTextCheckBox(Boolean(res.data?.processUrls));
            setMerge(Boolean(res.data?.merge));
            setSearchQuery(Boolean(res.data?.search));
            setPagination(Boolean(res.data?.Pagination));
            setEntireWebsite(Boolean(res.data?.entireWebsite));
            // Only update noOfPages if it's present in the response
            if (res.data?.pagination_max_iteractions) {
              setNoOfPages(res.data.pagination_max_iteractions);
            }
            setData(res.data);
          }
          setSavingCheck(false);
        });
    } catch (e) {
      console.log('Error updating checks in conversion: ', e);
      // Revert the checkbox state if the API call fails
      switch (label) {
        case 'merge':
          setMerge(!value);
          break;
        case 'processUrls':
          setTextCheckBox(!value);
          break;
        case 'search':
          setSearchQuery(!value);
          break;
        case 'Pagination':
          setPagination(!value);
          break;
        case 'entireWebsite':
          setEntireWebsite(!value);
          break;
        default:
          break;
      }
      setSavingCheck(false);
    }
  };
  
  return (
    <Modal show={show} onHide={handleClose} centered className="upload-modal">
      <Modal.Header closeButton>
        <Modal.Title>Start Transformation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="checkbox-container">
          <FormControlLabel
            control={
              <CustomCheckbox
                checked={merge}
                disabled={savingCheck}
                onChange={(e) => {
                  setMerge(e.target.checked);
                  updateCheck('merge', e.target.checked);
                }}
              />
            }
            label={
              <Tooltip 
                title="Enable to merge all results into a single row" 
                arrow classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}>
                <span className="merge-results-label">
                  Merge Results
                </span>
              </Tooltip>
            }
          />
          {/* <div className="checkbox-description">
            If enabled, it will return only one merged row of data
          </div> */}

          <FormControlLabel
            control={
              <CustomCheckbox
                checked={textCheckBox}
                disabled={savingCheck}
                onChange={(e) => {
                  setTextCheckBox(e.target.checked);
                  updateCheck('processUrls', e.target.checked);
                }}
              />
            }
            label={
              <Tooltip 
                title="Process URLs instead of text. Webpage content from each URL will be downloaded and processed. Each URL count as 1 transformation" 
                arrow classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}>
                <span className="process-urls-label">
                  Process URLs
                </span>
              </Tooltip>
            }
          />
          {/* <div className="checkbox-description">
            Extract all URLs from uploaded files and text and process URLs 1-by-1. Each URL will count as one transformation
          </div> */}

          {textCheckBox && (
            <>
              <FormControlLabel
                control={
                  <CustomCheckbox
                    checked={pagination}
                    disabled={savingCheck || entireWebsite || searchQuery}
                    onChange={(e) => {
                      const Pageinate = userPlan?.[0]?.subType?.Pagination || userPlan?.[0]?.subscriptionType?.Pagination;
                      if (!Pageinate || Pageinate === 0) {
                        toast('Upgrade your subscription', { type: 'error' });
                      } else if (Pageinate === 1) {
                        setPagination(e.target.checked);
                        updateCheck('Pagination', e.target.checked);
                        if (e.target.checked) {
                          setSearchQuery(false);
                          updateCheck('search', false);
                          setEntireWebsite(false);
                          updateCheck('entireWebsite', false);
                        }
                      }
                    }}
                  />
                }
                label="Pagination, Load More, Unlimited Scroll"
              />

              {pagination && (
                <div className="pagination-max-iterations mb-3" id="stepPages">
                  {/* <span className="text-format">Number of pages for pagination, scroll, load more: </span> */}
                  <TextField
                    type="number"
                    value={noOfPages}
                    min={1}
                    disabled={savingCheck}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value < 1) {
                        toast(`Value should be greater than or equal to 1`, {
                          type: 'warning',
                        });
                        setNoOfPages(1); // Ensure the value is set to 1 if it's less than 1
                      } else {
                        setNoOfPages(value);
                      }
                    }}
                    onBlur={() => {
                      const value = parseInt(noOfPages);
                      if (value >= 1) {
                        updateCheck('pagination_max_iteractions', value);
                      }
                    }}
                    placeholder="Enter max iterations"
                    variant="outlined"
                    size="small"
                    style={{ width: '100%', marginBottom: '10px' }}
                  />
                  <div className="pagination-support-text">
                    Set the maximum number of pages/scrolls.
                  </div>
                </div>
              )}

              <FormControlLabel
                control={
                  <CustomCheckbox
                    checked={entireWebsite}
                    disabled={savingCheck || pagination}
                    onChange={(e) => {
                      const EntireSite = userPlan?.[0]?.subType?.entireWebsite || userPlan?.[0]?.subscriptionType?.entireWebsite;
                      if (!EntireSite || EntireSite === 0) {
                        toast('Upgrade your subscription', { type: 'error' });
                      } else if (EntireSite === 1) {
                        setEntireWebsite(e.target.checked);
                        updateCheck('entireWebsite', e.target.checked);
                        if (e.target.checked) {
                          setPagination(false);
                          updateCheck('Pagination', false);
                        }
                      }
                    }}
                  />
                }
                label={
                  <Tooltip 
                    title="Up to 5 pages for each website. For more pages, please contact us: contacts@datatera.ai" 
                    arrow classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}>
                    <span className="entire-website-label">
                      Entire Website
                    </span>
                  </Tooltip>
                }
              />
              {/* <div className="checkbox-description">
                For more pages, please contact us: contacts@datatera.ai
              </div> */}
            </>
          )}

          <FormControlLabel
            control={
              <CustomCheckbox
                checked={searchQuery}
                disabled={savingCheck || pagination}
                onChange={(e) => {
                  setSearchQuery(e.target.checked);
                  updateCheck('search', e.target.checked);
                  if (e.target.checked && pagination) {
                    setPagination(false);
                    updateCheck('Pagination', false);
                  }
                }}
              />
            }
            label={
              <Tooltip 
                title="10 first rows only will be processed and enriched. For more rows contact us: contacts@datatera.ai" 
                arrow classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}>
                <span className="smart-ai-data-enrichment-label">
                  Smart AI Data Enrichment
                </span>
              </Tooltip>
            }
          />
          {/* <div className="checkbox-description">
            For up to 10 first rows. For more rows contact us: contacts@datatera.ai
          </div> */}
        </div>

        <Form.Control
          type="file"
          className="uploadField"
          id="upload_file_convert_background"
          onChange={(e) => {
            const tempFiles = e.target.files;
            if (Object.values(tempFiles)?.some((file) => file?.size / 1024 / 1024 > userPlan[0]?.maxFileSize) && userPlan?.length > 0) {
              toast(`Maximum file size should be ${userPlan[0]?.maxFileSize} MB`, { type: 'warning' });
            } else {
              setFilesSelected([...filesSelected, ...tempFiles]);
            }
            e.target.value = null;
          }}
          multiple={!textCheckBox}
          accept=".csv,.txt,.json,.docx,.png,.jpg,.jpeg,.xlsx,.html,.htm,.wav,.pptx,.xbrl,.xml,.pdf"
        />
        
        <label htmlFor="upload_file_convert_background" className="upload_file_convert_btn">
          Add Files
        </label>

        {/* <div className="file-formats">
          TXT, CSV, HTML, PDF, Images, PPTX, DOCX, XLSX, JSON, XML/XBRL, Audio
        </div> */}

        <div className="file-list">
          {filesSelected?.length > 0 && filesSelected.map((file, index) => (
            <div key={index} className="file-item">
              <p>{file?.name}</p>
              <img
                src={close}
                alt="close"
                width={16}
                height={16}
                onClick={() => {
                  const temp = [...filesSelected];
                  temp.splice(index, 1);
                  setFilesSelected(temp);
                }}
              />
            </div>
          ))}
        </div>
    
        <div className="textarea-section">
          <div className="textarea-label">
            Put Text or Website URLs here (minimum 10 characters)
          </div>
          <textarea
            className="upload-textarea"
            value={uploadText}
            onChange={(e) => setUploadText(e.target.value)}
            onPaste={(e) => {
              e.preventDefault();
              const pastedText = e.clipboardData.getData('text');
              const textarea = e.target;
              const start = textarea.selectionStart;
              const end = textarea.selectionEnd;
              const currentText = textarea.value;
              const newText = currentText.slice(0, start) + pastedText + currentText.slice(end);
              setUploadText(newText);
              e.target.value = newText;
              setTimeout(() => {
                textarea.selectionStart = textarea.selectionEnd = start + pastedText.length;
                textarea.focus();
              }, 0);
            }}
            placeholder="Paste any URLs or text here to transform it. If you want to transform websites, remember to enable 'ProcessURLs' checkbox."
          />
        </div>

        <button
          className="transform-button"
          disabled={!filesSelected.length && (!uploadText || uploadText.length < 10)}
          onClick={() => {
            if (uploadText) {
              const file = new File([uploadText], 'text.txt', { type: 'text/plain' });
              const temp = [...filesSelected, file];
              handleUploadFile(temp, '1', returnRowsLimit, model, true);
            } else {
              handleUploadFile(filesSelected, '1', returnRowsLimit, model, true);
            }
            handleClose();
          }}
        >
          Transform
        </button>
      </Modal.Body>
    </Modal>
  );
};

export default UploadModal;
