import {
  faCheckCircle,
  faEye,
  faEyeSlash,
  faPlugCircleBolt,
  faPlugCircleXmark,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, Form, Formik, useFormikContext } from 'formik';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Blocks } from 'react-loader-spinner';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import * as Yup from 'yup';
import api from '../../api';
import { Header } from '../../components/header';
import Sidebar from '../../components/sidebar';
import { ListContext } from '../../context/list';
import useWindowDimensions from '../../utiles/getWindowDimensions';
import './Integration.css';

const IntegrationItem = ({ item, onConnect, isActive, userId }) => {
  const [isTested, setIsTested] = useState(null);

  useEffect(() => {
    const checkIsTested = async () => {
      try {
        const response = await api.get(
          `/integrations/${userId}/${item._id}/is-tested`,
        );
        setIsTested(response.data.isTested);
      } catch (error) {
        console.error('Error fetching isTested status:', error);
        setIsTested(null);
      }
    };

    if (isActive) {
      checkIsTested();
    }
  }, [userId, item._id, isActive]);

  const handleClick = () => {
    if (isActive) {
      onConnect(item);
    }
  };

  let connectionIcon;
  let connectionIconColor;
  if (isTested === true) {
    connectionIcon = faPlugCircleBolt;
    connectionIconColor = 'green';
  } else if (isTested === false) {
    connectionIcon = faPlugCircleXmark;
    connectionIconColor = 'red';
  }

  return (
    // <div
    //   className={`integration-item ${isActive === 'false' ? 'disabled' : ''}`}
    //   onClick={handleClick}
    // >
    //   {isActive === 'true' &&
    //     isTested !== null && (
    //       <FontAwesomeIcon
    //         icon={connectionIcon}
    //         color={connectionIconColor}
    //         className="status-icon"
    //       />
    //     )}
    //   <img src={item.logo} alt={item.name} className="icon" />
    //   <p className="name">{item.name}</p>
    //   {isActive === 'false' && <div className="coming-soon">Coming Soon!</div>}
    // </div>
    <div
      className={`integration-item ${!isActive ? 'disabled' : ''}`}
      onClick={handleClick}
    >
      {isActive && isTested !== null && (
        <FontAwesomeIcon
          icon={connectionIcon}
          color={connectionIconColor}
          className="status-icon"
        />
      )}
      <img src={item.logo} alt={item.name} className="icon" />
      <p className="name">{item.name}</p>
      {!isActive && <div className="coming-soon">Enterprise Version</div>}
    </div>
  );
};

const formatLabel = (label) => {
  return label
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};

const DynamicFormInput = ({ field, label }) => {
  const { errors, touched, values, setFieldValue, handleChange } =
    useFormikContext();
  const isDisabled = field === 'OAuth_Redirect_URL';
  const isOAuthField = field === 'OAuth_Redirect_URL';
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  useEffect(() => {
    if (isOAuthField && !values[field]) {
      setFieldValue(
        field,
        `${process.env.BACKEND_URL}/api/v1/integrations/auth/google/callback`,
      );
    }
  }, [setFieldValue, field, isOAuthField, values]);

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  return (
    <div className="form-input">
      <label htmlFor={field}>{label}</label>
      {errors[field] && touched[field] && <span className="required">*</span>}
      <div className="input-group">
        <input
          type={isPasswordVisible ? 'text' : 'password'}
          name={field}
          value={values[field]}
          onChange={handleChange}
          className="input-field"
        />
        <button
          type="button"
          onClick={togglePasswordVisibility}
          className="icon-button"
        >
          <FontAwesomeIcon icon={isPasswordVisible ? faEyeSlash : faEye} />
        </button>
      </div>
    </div>
  );
};

const Integration = () => {
  const { width } = useWindowDimensions();
  const {
    list,
    setListItems,
    openSideBar,
    setOpenSideBar,
    setFetchConversions,
  } = useContext(ListContext);
  const user = JSON.parse(localStorage.getItem('user')) ?? {};
  const redirect_uri = `${process.env.BACKEND_URL}/api/v1/integrations/auth/google/callback`;
  const [isLoading, setIsLoading] = useState(true);
  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState({});
  const [state, setState] = useState({
    isPaneOpen: false,
    isPaneOpenLeft: false,
  });

  const [panelTitle, setPanelTitle] = useState('');
  const [panelLogo, setPanelLogo] = useState('');
  const [authFields, setAuthFields] = useState([]);
  const [lastConnection, setLastConnection] = useState(null);
  const [formIsSaved, setFormIsSaved] = useState(false);
  const [isTestingConnection, setIsTestingConnection] = useState(false);
  const [oauthWindow, setOauthWindow] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);

  const oauthWindowRef = useRef(null);

  useEffect(() => {
    return () => {
      if (oauthWindowRef.current && !oauthWindowRef.current.closed) {
        oauthWindowRef.current.close();
      }
    };
  }, []);

  const fetchItems = async () => {
    try {
      setIsLoading(true);
      const response = await api.get('/integrations');
      const sortedItems = response.data.sort((a, b) =>
        a.name.localeCompare(b.name),
      );
      setItems(sortedItems);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error('Error fetching data:', error);
    }
  };

  const fetchLastConnection = async (userId, integrationId) => {
    try {
      const response = await api.get(
        `/integrations/${userId}/${integrationId}/last-connection`,
      );

      if (response.data && response.data.data !== null) {
        setLastConnection(response.data.data);
        setFormIsSaved(true);
      } else {
        setLastConnection(null);
        setFormIsSaved(false);
      }
    } catch (error) {
      console.error('Error fetching last connection:', error);
      setLastConnection(null);
    }
  };

  useEffect(() => {
    fetchItems();
  }, []);

  const handleConnect = async (item) => {
    setState({ isPaneOpen: true });
    setPanelTitle(item.name);
    setPanelLogo(item.logo);
    setAuthFields(item.authFields);
    setSelectedItem(item);

    await fetchLastConnection(user?._id, item?._id);
  };

  // Define the initial form state dynamically based on authFields
  const getInitialValues = (fields) => {
    const initialValues = {
      connectionName: lastConnection ? lastConnection.name : `${panelTitle} #1`,
      connection_type: 'new',
      OAuth_Redirect_URL: redirect_uri,
    };
    fields.forEach((field) => {
      if (field !== 'OAuth_Redirect_URL') {
        initialValues[field] =
          lastConnection && lastConnection.authDetails[field]
            ? lastConnection.authDetails[field]
            : '';
      }
    });
    return initialValues;
  };

  // Define the validation schema dynamically based on authFields
  const getValidationSchema = (fields) => {
    const shape = {
      connectionName: Yup.string().required('Connection name is required'),
    };
    fields.forEach((field) => {
      shape[field] = Yup.string().required(`${formatLabel(field)} is required`);
    });
    return Yup.object().shape(shape);
  };

  useEffect(() => {
    if (lastConnection) {
      // Compare initialValues with lastConnection to set formIsSaved
      const initialValues = getInitialValues(authFields);
      const hasChanges = Object.keys(initialValues).some(
        (key) => initialValues[key] !== (lastConnection.authDetails[key] ?? ''),
      );
      setFormIsSaved(!hasChanges);
    }
  }, [lastConnection, authFields]);

  const updateIsTestedStatus = async (connectionId, isTested) => {
    try {
      const response = await api.patch(
        `/integrations/${connectionId}/update-isTested`,
        {
          isTested: isTested,
        },
      );

      if (response.status === 200) {
        // console.log('isTested status updated successfully:', response.data);
      } else {
        console.log('Failed to update isTested status');
      }
    } catch (error) {
      console.error(
        'Error updating isTested status:',
        error.response ? error.response.data : error,
      );
    }
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const {
      connectionName,
      OAuth_Redirect_URL,
      connection_type,
      ...authDetails
    } = values;

    setIsTestingConnection(true);

    try {
      const response = await api.post('/integrations/save-connection', {
        name: connectionName,
        user: user._id,
        integration: selectedItem._id,
        authDetails: authDetails,
      });

      const connectionId = response.data._id;

      //const itemName = selectedItem.name.toLowerCase();
      const itemName = selectedItem.name
        .toLowerCase()
        .replace(/\s+/g, '')
        .trim();

      let testEndpoint = `/integrations/${itemName}/test`;

      console.log('testEndpoint', testEndpoint);

      let requestMethod = 'get';
      let requestOptions = {};
      if (
        itemName === 'slack' ||
        itemName === 'todoist' ||
        itemName === 'monday'
      ) {
        requestMethod = 'post';
      }

      if (requestMethod === 'get') {
        testEndpoint += `?connectionId=${connectionId}`;
      } else {
        requestOptions = {
          connectionId: connectionId,
        };
      }

      if (selectedItem?.connectionType === 'oauth') {
        if (itemName === 'googlesheets') {
          try {
            let res = await api.post(`/integrations/googlesheet/authorize`, {
              clientID: authDetails.Client_ID,
              connectionID: connectionId,
            });

            const googleAuthWindow = window.open(
              res?.data?.url,
              'Google OAuth',
              'width=500,height=600',
            );

            const handleOAuthWindowClose = () => {
              console.log('OAuth window closed by user');
              setIsTestingConnection(false);
            };

            googleAuthWindow.addEventListener(
              'beforeunload',
              handleOAuthWindowClose,
            );

            setOauthWindow(googleAuthWindow);

            oauthWindowRef.current = googleAuthWindow;

            const handleMessage = async (event) => {
              //console.log(event.data)
              if (event.data === 'oauth-success') {
                //console.log('OAuth successful');

                if (oauthWindowRef.current && !oauthWindowRef.current.closed) {
                  oauthWindowRef.current.close();
                }

                oauthWindowRef.current = null;
                //googleAuthWindow.close();
                setOauthWindow(null);

                setIsTestingConnection(false);
                await updateIsTestedStatus(connectionId, true);
                await fetchItems();
              }
            };
            window.addEventListener('message', handleMessage);

            // return () => {
            //   window.removeEventListener('message', handleMessage);
            //   if (oauthWindow) {
            //     oauthWindow.removeEventListener('beforeunload', handleOAuthWindowClose);
            //     oauthWindow.close();
            //   }
            // };
          } catch (error) {
            console.error('Error initiating Google OAuth:', error);
            setIsTestingConnection(false);
          }
        } else if (itemName === 'x(formerlytwitter)') {
          let res = await api.post(`/integrations/twitter/authorize`, {
            clientID: authDetails.Client_ID,
            clientSecret: authDetails.Client_Secret,
          });

          console.log(res.data);
        }

        resetForm();
        setSubmitting(false);
        setFormIsSaved(true);

        return;
      }

      // const testConnectionResponse = await api[requestMethod](testEndpoint, {
      //   headers,
      // });

      const testConnectionResponse = await api[requestMethod](
        testEndpoint,
        requestOptions,
      );

      if (testConnectionResponse.data.success === true) {
        console.log('Token tested successfully.');
        await updateIsTestedStatus(connectionId, true);
        await fetchItems();
      } else {
        console.log(
          'Token test failed with status:',
          testConnectionResponse.status,
        );
        await updateIsTestedStatus(connectionId, false);
        await fetchItems();
      }

      resetForm();
      // setState({ isPaneOpen: false });
      setIsTestingConnection(false);
      await fetchLastConnection(user._id, selectedItem._id);
    } catch (error) {
      console.error(
        'Error saving data:',
        error.response ? error.response.data : error,
      );
      setIsTestingConnection(false);
      await fetchLastConnection(user._id, selectedItem._id);
    }

    setSubmitting(false);
    setFormIsSaved(true);
  };

  const requiredAsteriskStyle = {
    color: 'red',
    marginLeft: '0.2em',
  };

  const deleteConnection = async (connectionId, resetForm) => {
    try {
      setIsDeleting(true);
      await api.delete(`/integrations/connection/${connectionId}`);
      setIsDeleting(false);
      setItems((prevItems) =>
        prevItems.filter((item) => item._id !== connectionId),
      );
      resetForm();
      setLastConnection(null);
      // setSubmitting(false);
      await fetchItems();
    } catch (error) {
      console.error('Error deleting connection:', error);
      setIsDeleting(false);
    }
  };

  return (
    <>
      <Header />
      <div className="main w-100">
        <div className="sidebar">
          <Sidebar />
        </div>

        <h2
          className={`${
            !openSideBar ? 'heading_open' : 'heading_close'
          } heading`}
        >
          Data Export Marketplace
        </h2>
        {isLoading ? (
          <div className="loader-container">
            <Blocks color="#4fa94d" height={80} width={80} visible={true} />
          </div>
        ) : (
          <div
            className={`${
              !openSideBar ? 'integration-container_open' : 'integration-container_close'
            } integration-container`}
          >
            {items.map((item) => (
              <IntegrationItem
                key={item._id}
                item={item}
                onConnect={handleConnect}
                isActive={item.isActive}
                userId={user._id}
              />
            ))}
          </div>
        )}
      </div>

      <SlidingPane
        className="some-custom-class"
        width="750px"
        overlayClassName="some-custom-overlay-class"
        isOpen={state.isPaneOpen}
        title={
          <div className="title-with-logo">
            <div className="logo-circle">
              <img src={panelLogo} alt="Logo" className="logo-image" />
            </div>
            {`Connect ${panelTitle} Account`}
          </div>
        }
        // subtitle="Manage connections from here. All connection are secure."
        onRequestClose={() => {
          setState({ isPaneOpen: false });
        }}
      >
        {authFields.length > 0 && (
          <Formik
            initialValues={getInitialValues(authFields)}
            validationSchema={getValidationSchema(authFields)}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              handleSubmit(values, { setSubmitting, resetForm });
              setFormIsSaved(true);
            }}
            enableReinitialize
          >
            {({
              isSubmitting,
              errors,
              touched,
              values,
              handleChange,
              resetForm,
            }) => {
              const handleValueChange = (e) => {
                handleChange(e);
                setFormIsSaved(false);
              };
              return (
                <Form>
                  <div className="pane-content">
                    <div className="pane-heading">{panelTitle}</div>
                    <ul className="pane-list">
                      <li className="pane-list-item">
                        <Field
                          type="radio"
                          id="new_connection"
                          name="connection_type"
                          value="new"
                        />
                        <label htmlFor="new_connection">
                          Add New Connection
                        </label>
                      </li>
                      {/* <li className="pane-list-item">
                    <Field
                      type="radio"
                      id="existing_connection"
                      name="connection_type"
                      value="existing"
                    />
                    <label htmlFor="existing_connection">
                      Select Existing Connection
                    </label>
                  </li> */}
                    </ul>
                  </div>
                  <div className="pane-content">
                    {isTestingConnection && (
                      <div className="pane-heading">
                        <span>Testing connection </span>
                        <Blocks
                          color="#4fa94d"
                          height={30}
                          width={30}
                          visible={true}
                        />
                      </div>
                    )}

                    {lastConnection &&
                      !isTestingConnection &&
                      (lastConnection.isTested === true ? (
                        <div className="pane-heading success">
                          <span>
                            Connection tested successfully &nbsp;
                            <FontAwesomeIcon icon={faCheckCircle} />
                          </span>
                          <button
                            disabled={isDeleting}
                            type="button"
                            className="delete-button"
                            onClick={() =>
                              deleteConnection(lastConnection._id, resetForm)
                            }
                          >
                            Delete
                          </button>
                        </div>
                      ) : lastConnection.isTested === false ? (
                        <div className="pane-heading fail">
                          <span>
                            Connection test failed &nbsp;
                            <FontAwesomeIcon icon={faTimesCircle} />
                          </span>
                          <button
                            disabled={isDeleting}
                            type="button"
                            className="delete-button"
                            onClick={() =>
                              deleteConnection(lastConnection._id, resetForm)
                            }
                          >
                            {isDeleting ? 'Deleting...' : 'Delete'}
                          </button>
                        </div>
                      ) : null)}

                    <label htmlFor="connectionName">
                      Connection Name
                      {errors.connectionName && touched.connectionName && (
                        <span className="required">*</span>
                      )}
                    </label>
                    <Field
                      type="text"
                      name="connectionName"
                      className="input-field"
                      value={values.connectionName}
                      onChange={handleChange}
                    />

                    <div className="connect-text">
                      {`Please provide credentials of ${panelTitle} account to allow access.`}
                    </div>
                    <div className="pane-content">
                      {authFields.map((field) => (
                        <DynamicFormInput
                          key={field}
                          field={field}
                          label={formatLabel(field)}
                        />
                      ))}
                    </div>
                  </div>

                  <div className="pane-footer">
                    <button
                      type="submit"
                      className="footer-button"
                      disabled={isSubmitting || formIsSaved}
                    >
                      {formIsSaved ? 'Saving...' : 'Save & Test'}
                    </button>
                    <button
                      type="button"
                      className="footer-button"
                      onClick={() => setState({ isPaneOpen: false })}
                    >
                      Cancel
                    </button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        )}
      </SlidingPane>
    </>
  );
};

export default Integration;
