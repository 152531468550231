import { Grid } from "@mui/material"
import AuthImage from '../../assets/images/auth.png'
import Logo from '../../assets/images/logo.jpg'


import './authLayout.css'
const AuthLayout=({children})=>{
return(
    <Grid container>
        <Grid item xs={12} md={5}>
    
        <img src={Logo} className="authImageLayoutLogo"  width={90} height={90} />


     <img src={AuthImage}  className="authImage" />

    
        </Grid>
        <Grid item xs={12} md={7}>
{children}
</Grid>
    </Grid>
)
}
export default AuthLayout